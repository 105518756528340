import React from 'react';
import PropTypes from 'prop-types';

import { attempt, get, isEmpty } from 'lodash';
import { Col, Row } from 'antd';
import URL from 'url-parse';
import Pagination from '~/components/pagination';
import CmsBanner from '../cms/CmsBanner';
import List from './List';
import Filter from './Filter';
import { renderBrand } from '../category/BrandList';
import { renderFaqs } from '../contact/Contact';
import Helmet from '../page/Helmet';
import Breadcrumb from '../page/Breadcrumb';
import HeadingTitle from '../page/HeadingTitle';
import { PRODUCT_PER_PAGE } from './SearchView';
import { PriorityPage } from '../cms';

const specificPages = {
  "/new-arrivals": { name: 'New Arrivals', q: "new-arrivals" },
}

export default class ProductList extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      location: PropTypes.shape({
        query: PropTypes.shape({
          q: PropTypes.string,
          p: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
          filters: PropTypes.string,
          orderBy: PropTypes.string,
        }).isRequired,
        pathname: PropTypes.string.isRequired,
      }).isRequired,
      params: PropTypes.shape({
        categoryUrlSlug: PropTypes.string,
      }).isRequired,
    }).isRequired,
    viewer: PropTypes.shape({
      products: PropTypes.shape({
        redirect: PropTypes.string,
        totalCount: PropTypes.number,
        filters: PropTypes.string,
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    catalogues: PropTypes.shape({
      totalCount: PropTypes.number,
      filters: PropTypes.string,
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      hasMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    catalogues: null,
  }

  constructor(props) {
    super(props);

    let orderBy = get(this.props.match.location.query, 'orderBy');

    if (typeof orderBy === 'string') {
      const [field, direction] = orderBy ? orderBy.split("_") : [null, null];
      orderBy = {
        field, direction
      };
    }

    this.state = {
      filters: this.extractFilters(this.props.match.location.query),
      orderBy,
    };

    this.curPage = parseInt(get(this.props.match.location.query, 'p', 1), 10);

    this.redirect(props);
  }

  componentWillReceiveProps = (nextProps) => {
    this.resetOrderBy(this.props.match.location.query, nextProps.match.location.query);

    const newQuery = nextProps.match.location.query.q;
    const oldQuery = this.props.match.location.query.q;
    if (newQuery !== oldQuery) {
      this.curPage = 1;
      this.search(newQuery);
    }
  }

  componentDidUpdate = (prevProps) => {
    const currentQuery = this.props.match.location.query;
    const prevQuery = prevProps.match.location.query;

    this.resetFiltersOnChange(prevQuery, currentQuery);
    this.redirect(prevProps);
  }

  getDescripiton = ({ category, subcategory }) => {
    if (get(subcategory, 'description')) {
      return <div dangerouslySetInnerHTML={{ __html: subcategory.description }} />;
    }
    else if (get(category, 'description') && !subcategory) {
      return <div dangerouslySetInnerHTML={{ __html: category.description }} />;
    }
    return null;
  }

  getDescripiton2 = ({ category, subcategory }) => {
    if (get(subcategory, 'description2')) {
      return <div dangerouslySetInnerHTML={{ __html: subcategory.description2 }} style={{ marginTop: '10px' }} />;
    }
    else if (get(category, 'description2') && !subcategory) {
      return <div dangerouslySetInnerHTML={{ __html: category.description2 }} style={{ marginTop: '10px' }} />;
    }
    return null;
  }

  getQuery = () => {
    const { pathname, query } = this.props.match.location;
    return get(specificPages, pathname, query);
  }

  getSearchHeading = (loc) => {
    const { query, pathname } = loc;
    const name = get(specificPages[pathname], 'name');
    const queryString = get(query, "q", "");

    if (name) {
      return (
        <div>
          <Helmet category={{ name }} />
          <h2>{name}</h2>
        </div>
      );
    }

    return (
      <div>
        <Helmet category={{ name: `Search for ${queryString}` }} />
        <h2 style={{ marginBottom: 0, marginTop: 5 }}>
          Search Results for <b>&quot;{queryString}&quot;</b>
        </h2>
        {queryString.length > 200 && (
          <div>
            <small>
              Your search term has been limited to 200 charachters.
            </small>
          </div>
        )}
      </div>
    );
  }

  getHeading = (loc, category, subcategory, brand) => {
    if (isEmpty(category)) {
      return this.getSearchHeading(loc);
    }

    return (
      <div>
        <Helmet category={category} subcategory={subcategory} brand={brand} location={loc} />
        <Breadcrumb category={category} subcategory={subcategory} location={loc} brand={brand} />
        <HeadingTitle category={category} subcategory={subcategory} brand={brand} location={loc} />
      </div>
    )
  }

  setCheckable = (filterCode, e) => {
    const { checked } = e.target;
    const { filters, orderBy } = this.state;

    if (checked) {
      filters[filterCode] = checked;
    } else {
      delete filters[filterCode];
    }

    this.setFilters(filters, orderBy);
  }

  setFilters = (filters, orderBy) => {
    this.setState({ filters }, function cb() {
      if (Object.keys(filters).length > 0) {
        this.props.match.location.query.filters = JSON.stringify(filters);
      } else {
        delete this.props.match.location.query.filters;
      }

      this.curPage = 0;
      this.props.match.location.query.p = this.curPage;

      const { query } = this.props.match.location;
      this.rememberScrollPosition(query, filters, orderBy);
    });
  }

  getFacets = (object) => {
    return get(object, 'facets', [])
  }

  extractFilters = (ob) => {
    const f = get(ob, 'filters', '{}');
    const filters = attempt(JSON.parse.bind(null, f));
    if (filters instanceof Error) {
      return {};
    }
    return filters;
  }

  redirect = (props) => {
    const redirect = get(props, 'viewer.products.redirect');

    if (redirect) {
      try {
        const { pathname } = new URL(redirect);
        this.props.router.replace(pathname);
      } catch (e) {
        if (e instanceof TypeError) {
          this.props.router.replace(redirect);
        }
      }
      return true;
    }
    return false;
  }

  updatePriceRange = (filterObj) => {
    const { filters, orderBy } = this.state;

    Object.keys(filterObj).forEach(key => {
      const value = filterObj[key];

      if (value) {
        filters[key] = value;
      } else {
        delete filters[key];
      }
    });

    this.setFilters(filters, orderBy);
  }

  updateFilters = (filterCode, optionKey, e) => {
    const checked = typeof (e) === 'boolean' ? e : get(e, 'target.checked');
    const { filters, orderBy } = this.state;

    if (checked) {
      if (!filters[filterCode]) {
        filters[filterCode] = [];
      }
      filters[filterCode].push(optionKey);
    } else if (filters[filterCode].length > 1) {
      const index = filters[filterCode].indexOf(optionKey);
      filters[filterCode].splice(index, 1);
    } else {
      delete filters[filterCode];
    }

    this.setFilters(filters, orderBy);
  }

  resetFilters = (filterCode) => {
    let { filters } = this.state;
    const { orderBy } = this.state;

    if (filterCode === undefined) {
      filters = {};
    } else {
      delete filters[filterCode];
    }

    this.setState({ filters }, function cb() {
      if (Object.keys(filters).length > 0) {
        this.props.match.location.query.filters = JSON.stringify(filters);
      }
      else {
        delete this.props.match.location.query.filters;
      }

      this.curPage = 0;
      this.props.match.location.query.p = this.curPage;
      this.props.router.push(this.props.match.location);

      const { query } = this.props.match.location;
      this.rememberScrollPosition(query, filters, orderBy);
    });
  }

  // reset filters navgation changes
  resetFiltersOnChange = (prevQuery, currentQuery) => {
    if (prevQuery.filters && (typeof currentQuery.filters === 'undefined' || currentQuery.filters === {})) {
      this.setState({ filters: {} });
    }
    else if (currentQuery.filters && !Object.is(prevQuery.filters, currentQuery.filters)) {
      this.setState({ filters: this.extractFilters(currentQuery) });
    }
  }

  // reset orderby navgation changes
  resetOrderBy = (prevQuery, currentQuery) => {
    if (typeof prevQuery.orderBy !== 'undefined' && typeof currentQuery.orderBy === 'undefined') {
      this.setState({ orderBy: null });
    }
  }

  search = (q) => {
    const refetchVariables = {
      query: q,
    };

    this.props.relay.refetchConnection(PRODUCT_PER_PAGE, () => {
      this.setState({ filters: this.extractFilters(this.props.match.location.query) })
    }, refetchVariables);
  }

  rememberScrollPosition = () => {
    const { location } = this.props.match;

    this.curPage += 1;

    location.query.p = this.curPage;

    /* FIXME
     * This push is to update browser url upon loading more
     * e.g. /search?p=1&q=makita to search?p=2&q=makita
     * so that scroll state can be restored on refreshing or next/prev changes.
     * However, it causes the query to be fetched twice.
     */
    this.props.router.push({ ...location, shouldUpdateScroll: false });
  }

  refetch = (filters) => {
    const { orderBy } = this.state;

    const refetchVariables = {
      filters,
      query: this.getQuery().q,
      orderBy,
    };

    this.props.relay.refetchConnection(PRODUCT_PER_PAGE, null, refetchVariables);
  }

  sort = (orderBy) => {
    const [field, direction] = orderBy ? orderBy.split("_") : [null, null];

    const { filters } = this.state;

    orderBy = field ? { field, direction } : null;

    this.setState({ orderBy }, function cb() {
      if (orderBy) {
        this.props.match.location.query.orderBy = `${field}_${direction}`;
      }
      else {
        delete this.props.match.location.query.orderBy;
      }

      const { query } = this.props.match.location;
      this.curPage = 0;

      this.rememberScrollPosition(query, filters, orderBy);
    });
  }

  render() {
    const { viewer, match, router } = this.props;
    const { location, params } = match;
    const category = get(viewer, 'categories.edges[0].node', {});
    const subcategory = get(this.props.catalogues, 'subcategory', {});
    const featuredBrands = get(category, 'featuredBrands.edges', []);
    const brand = get(category, 'catalogues.brand', {});

    let faqs = get(category, 'faqs.edges', []);

    if (subcategory) {
      faqs = get(subcategory, 'faqs.edges', []);
    }

    // stop rendering if this page means to do a search query redirection
    if (viewer.products) {
      if (viewer.products.edges.length === 0 && viewer.products.redirect !== null) {
        return null;
      }
    }

    let products = [];

    let facets = [];

    let totalCount = 0;

    if (this.props.catalogues) {
      products = get(this.props.catalogues, 'edges', []);
      facets = this.getFacets(this.props.catalogues);
      totalCount = this.props.catalogues.totalCount; // eslint-disable-line prefer-destructuring
    }

    if (viewer.products) {
      products = get(viewer.products, 'edges', []);
      facets = this.getFacets(viewer.products)
      totalCount = viewer.products.totalCount; // eslint-disable-line prefer-destructuring
    }

    return (
      <div>
        {this.getHeading(location, category, subcategory, brand)}

        {params.categoryUrlSlug === 'priority' && <PriorityPage viewer={viewer} />}
        <div style={{ fontSize: '14px' }}><b>{totalCount}</b> Results Found</div>
        {category.name === "Redemption Offers" && (
          <Row>
            {featuredBrands.map(({ node: b }) => {
              const url = `/category/${b.urlSlug}-redemption`;
              return (
                <Col
                  key={b.id}
                  xs={12}
                  sm={8}
                  md={6}
                  lg={4}
                  xl={4}
                  style={{
                    margin: '-1px 0 0 -1px',
                    height: '185px',
                    textAlign: 'center',
                    border: '1px solid #e8e8e8'
                  }}
                >
                  {renderBrand(b, url)}
                </Col>
              );
            })}
          </Row>
        )}

        <CmsBanner viewer={viewer} location={location} />

        {this.getDescripiton({ category, subcategory })}

        {facets.length > 0 && (
          <Filter
            match={match}
            filters={facets}
            totalCount={totalCount}
            activeFilters={this.state.filters}
            orderBy={this.state.orderBy}
            sort={this.sort}
            configs={get(viewer, 'configs', {})}
            resetFilters={this.resetFilters}
            updateFilters={this.updateFilters}
            updatePriceRange={this.updatePriceRange}
            setCheckable={this.setCheckable}
          />
        )}

        <div>
          {products.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'end',
                margin: '10px 0px 5px 0px',
              }}
            >
              <div
                style={{
                  flex: '1',
                  textAlign: 'right'
                }}
              >
                <Pagination
                  totalCount={totalCount}
                  match={this.props.match}
                  router={this.props.router}
                  name="top"
                />
              </div>
            </div>
          )}

          <List viewer={viewer} data={products} router={router} />

          {products.length > 0 && (
            <div
              style={{
                textAlign: 'right',
                marginTop: '10px'
              }}
            >
              <Pagination
                totalCount={totalCount}
                match={this.props.match}
                router={this.props.router}
                name="bottom"
              />
            </div>
          )}
        </div>

        {this.getDescripiton2({ category, subcategory })}
        {renderFaqs(faqs)}

      </div>
    );
  }
}
